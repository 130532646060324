var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('div', {
    staticClass: "flex-col w-full bg-white shadow-sm rounded-lg"
  }, [_c('div', {
    staticClass: "w-full flex items-center justify-between rounded-lg px-4 mt-4 mb-3 py-3"
  }, [_c('div', {
    staticClass: "w-2/6 relative"
  }, [_c('i', {
    staticClass: "fa-solid fa-magnifying-glass text-gray-400 text-xl absolute top-2 left-3"
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.query_search,
      expression: "query_search"
    }],
    staticClass: "p-2.5 pl-5 mb-1 w-full text-sm border rounded-lg",
    attrs: {
      "placeholder": "Search by Books Name or ID",
      "type": "search"
    },
    domProps: {
      "value": _vm.query_search
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.query_search = $event.target.value;
      }, _vm.onSearch],
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.onSearch.apply(null, arguments);
      }
    }
  }), _vm.typing ? _c('small', {
    staticClass: "pt-3"
  }, [_c('em', [_vm._v(" typing ... ")])]) : _vm._e()]), _c('div', {
    staticClass: "relative w-3/4 flex justify-end"
  }, [_c('button', {
    staticClass: "text-gray-500 mr-3 bg-blue-500 border focus:outline-none font-medium rounded-lg text-sm px-2 py-2.5 text-center",
    attrs: {
      "type": "button"
    }
  }, [_vm.roleID !== 5 ? _c('router-link', {
    staticClass: "text-white px-1 py-2",
    attrs: {
      "to": {
        name: _vm.BookCreatePath.name
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add New ")]) : _vm._e()], 1), _c('button', {
    staticClass: "text-gray-500 bg-white border focus:outline-none font-medium rounded-lg text-sm px-2 py-2.5 text-center",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.toggleDropdown
    }
  }, [_c('i', {
    staticClass: "fa-solid fa-filter"
  }), _vm._v(" Filter "), _c('i', {
    staticClass: "fa-solid fa-chevron-down"
  })]), _c('FilterComponent', {
    attrs: {
      "isDropdownVisible": _vm.isDropdownVisible
    },
    on: {
      "update:isDropdownVisible": function ($event) {
        _vm.isDropdownVisible = $event;
      },
      "update:is-dropdown-visible": function ($event) {
        _vm.isDropdownVisible = $event;
      },
      "reloadPage": _vm.reloadPage,
      "input": _vm.handleFilterInput
    }
  })], 1)]), _c('div', [_c('TableBooks', {
    staticClass: "border-b border-gray-50",
    attrs: {
      "id": "my-table",
      "books": _vm.ebooks,
      "currentPage": _vm.currentPage,
      "perPage": _vm.perPage,
      "isLoading": _vm.isLoading,
      "reloadPage": _vm.reloadPage
    },
    on: {
      "sort-by": _vm.handleSortBy
    }
  })], 1), _c('div', {
    staticClass: "flex bg-white justify-between pl-5 pr-3 pt-4 mb-5 border-t border-gray-100 mt-1"
  }, [_c('div', {
    staticClass: "font-semibold"
  }, [_c('span', {
    staticClass: "text-gray-500 font-normal"
  }, [_vm._v("Showing ")]), _vm._v(" " + _vm._s(_vm.currentPage) + "-" + _vm._s(_vm.perPage) + " "), _c('span', {
    staticClass: "text-gray-500 font-normal"
  }, [_vm._v("of")]), _vm._v(" " + _vm._s(_vm.formatPriceBasic(_vm.totalRows)) + " ")]), _c('div', {
    staticClass: "mb-2"
  }, [_c('t-pagination', {
    attrs: {
      "total-items": Number(_vm.totalRows),
      "per-page": _vm.perPage
    },
    on: {
      "change": _vm.onClickPage
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bg-white w-full rounded-lg mt-4 mb-3 px-4 py-2.5"
  }, [_c('label', [_vm._v("Books")]), _c('h4', {
    staticClass: "text-lg font-semibold"
  }, [_vm._v("All Books")])]);
}]

export { render, staticRenderFns }