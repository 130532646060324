<template>
  <div>
    <div class="bg-white w-full rounded-lg mt-4 mb-3 px-4 py-2.5">
      <label>Books</label>
      <h4 class="text-lg font-semibold">All Books</h4>
    </div>
    <div class="flex-col w-full bg-white shadow-sm rounded-lg">
      <div class=" w-full  flex items-center justify-between rounded-lg px-4 mt-4 mb-3 py-3">
      <div class="w-2/6 relative">
        <i class="fa-solid fa-magnifying-glass text-gray-400 text-xl absolute top-2 left-3"></i>
        <input placeholder="Search by Books Name or ID"
          class="p-2.5 pl-5 mb-1 w-full text-sm border rounded-lg" type="search" v-model="query_search"
          @input="onSearch" v-on:keyup.enter="onSearch" />
        <small class="pt-3" v-if="typing"> <em> typing ... </em> </small>
      </div>
      <div class="relative w-3/4 flex justify-end">
        <button 
          class="text-gray-500 mr-3 bg-blue-500 border focus:outline-none font-medium rounded-lg text-sm px-2 py-2.5 text-center"
          type="button">
          <router-link
                 v-if="roleID !==5"
                  class="text-white px-1 py-2"
                  :to="{ name: BookCreatePath.name }"
                >
                  <i class="fa fa-plus"></i> Add New
                </router-link>
        </button>
        <button @click="toggleDropdown" 
          class="text-gray-500 bg-white border focus:outline-none font-medium rounded-lg text-sm px-2 py-2.5 text-center"
          type="button"> <i class="fa-solid fa-filter"></i> Filter <i class="fa-solid fa-chevron-down"></i>
        </button>
        <FilterComponent
          :isDropdownVisible.sync="isDropdownVisible"
          @reloadPage="reloadPage"
          @input="handleFilterInput"
        />
  </div>
    </div>
    <div >
      <TableBooks class="border-b border-gray-50 " id="my-table" :books="ebooks" :currentPage="currentPage" :perPage="perPage" :isLoading="isLoading"
      :reloadPage="reloadPage" @sort-by="handleSortBy" />
    </div>
    <div class="flex bg-white justify-between pl-5 pr-3 pt-4 mb-5 border-t border-gray-100 mt-1">
        <div class="font-semibold">
          <span class="text-gray-500 font-normal">Showing </span> {{currentPage}}-{{perPage}} <span class="text-gray-500 font-normal">of</span> {{ formatPriceBasic(totalRows) }}
        </div>
        <div class="mb-2">
          <t-pagination 
          :total-items="Number(totalRows)"
          :per-page="perPage"
          v-model="currentPage"
          @change="onClickPage" 
          />
        </div>
    </div>
    </div>
  </div>

</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import TableBooks from '../../components/ebook/table.vue';
import FilterComponent from '../../components/form/FilterComponent.vue'
import { EventBus } from '../../event-bus';
import 'flowbite';
import 'flowbite/dist/flowbite.css';
import { BookCreatePath } from '../../router/content';
import constant from '../../store/constant';
//import SortComponent from '../../components/form/SortComponent.vue'

const ITEM_STATUS = Object.entries(constant.ITEM_STATUS).map((item) => {
  return { text: item[0], value: item[1] };
});
const ITEM_CONTENT_TYPE = Object.entries(constant.ITEM_CONTENT_TYPE).map((item) => {
  return { text: item[0], value: item[1] };
});

const { ROLES } = constant;
export default {
  name: 'ebooks',
  components: {
    TableBooks,
    FilterComponent,
  //  SortComponent
  },
  data() {
    return {
      BookCreatePath,
      query_search: null,
      currentPage: 1,
      perPage: 50,
      sortOrder: [],
      vendor: [],
      vendorSearchOptions: [],
      isVendorSearch: false,
      status: null,
      isDropdownVisible: false,
      contentType: null,
      createdSort: null,
      statusOptions: [
        { text: '---Status [All]---', value: null },
        ...ITEM_STATUS,
      ],
      contentTypeOptions: [
        { text: '---Content Type [All]---', value: null },
        ...ITEM_CONTENT_TYPE,
      ],
      debounce: null,
      roleID: JSON.parse(localStorage.getItem("session_auth")).role_id,
      typing: false,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Ebook | Content Management System Ebooks Gramedia.com';
      },
    },
    success: function () {
      // if(this.success){
      //   EventBus.$emit('show-toast', {
      //     message:this.message,
      //     type: 'success',
      //     });
      //     setTimeout(() => {
      //       this.$router.push({ path: "/" });
      //     }, 1500);
      // }
    },
    isError: function () {
      if (this.isError) {
        EventBus.$emit('show-toast', {
          message: ' Opps .. Something is wrong',
          type: 'success',
        });
      }
    }
  },
  computed: {
    ...mapGetters('auth', { auth: 'auth' }),
    ...mapGetters('ebooks', ['ebooks']),
    ...mapState({
      isLoading: (state) => state.ebooks.isLoading,
      isError: (state) => state.ebooks.isError,
      totalRows: (state) => state.ebooks.totalRows,
    }),

    isModalVisibleDelete() {
            return this.showModalDelete
        }
  },
  created() {
    this.actionFirst();
  },
  methods: {
    ...mapActions('vendors', ['searchVendors']),
    ...mapActions('ebooks', ['fetchEbooks', 'searchBooks']),
  
    actionFirst() {
      const query = this.$route.query;
      this.currentPage = query.page ?? this.currentPage;
      this.perPage = query.limit ?? this.perPage;
      this.query_search = query.q ?? this.query_search;
      this.status = query.status ?? this.status;
      if (typeof (query.vendor_id) == "string") {
        const vendor = query.vendor_id.split("#");
        this.vendor = [{ id: vendor[0], name: vendor[1] }];
      } else {
        this.vendor = (query.vendor_id ?? []).map(val => {
          const vendor = val.split("#");
          return {
            id: vendor[0],
            name: vendor[1],
          }
        });
      }
      this.reloadPage();
    },
    actionGetEbooks() {
      this.vendor = [];
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
      };
      this.fetchEbooks(payload);
      this.setQuery(payload);
    },
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    setContentType(getContentType) {
      this.contentType = getContentType;
      this.reloadPage();
    },
    handleFilterInput({ contentType, status, vendor }) {
      this.contentType = contentType;
      this.status = status;
      this.vendor = vendor;
      this.reloadPage();
    },
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    onSearch(event) {
      this.query_search = event.target.value;
      this.currentPage = 1;
      if (!this.query_search && !this.vendor.length) {
        this.actionGetEbooks();
        clearTimeout(this.debounce);
        return;
      }
      this.typing = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.typing = false;
        this.actionSearchEbooks();
      }, 600);
    },
    onClearSearch() {
      this.query_search = null;
      this.vendor = [];
      this.status = null;
      this.currentPage = 1;
      this.contentType = null;
      this.contentTypeOptions = []
      this.createdSort = null;
      this.reloadPage();
    },
    onClickPage(page) {
      this.currentPage = page;
      this.reloadPage();
    },
    actionSearchEbooks() {
      const payload = {
        q: this.query_search ?? '',
        page: this.currentPage,
        limit: this.perPage,
        vendor_id: this.vendor.map((val) => val.id),
        status: this.status,
        content_type: this.contentType,
        sort:this.sortOrder.criteria,
        order:this.sortOrder.order
      };
      this.searchBooks(payload);
      payload.vendor_id = this.vendor.map((val) => `${val.id}#${val.name}`);
      this.setQuery(payload);
    },
    toggleList() {
      this.showList = !this.showList;
    },
    // handleDropdownToggle() {
    //   this.toggleDropdown = !this.toggleDropdown
    // },
    vendorFind(query) {
      if (!query) return;

      this.isVendorSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        let payload = {
          name: query,
          limit: 10,
        };
        this.searchVendors(payload)
          .then((response) => {
            this.vendorSearchOptions = response.data.data.rows;
            this.isVendorSearch = false;
          })
          .catch(() => {
            this.isVendorSearch = false;
          });
      }, 600);
    },
    reloadPage() {
      if (!this.query_search && !this.vendor.length && !this.status && !this.contentType && !this.createdSort)
        this.actionGetEbooks();
      else this.actionSearchEbooks();
    },
    vendorChange() {
      this.currentPage = 1;
      this.reloadPage();
    },
    vendorRemove() {
      this.vendor = [];
      if (this.query_search) this.actionSearchEbooks();
      else this.actionGetEbooks();
    },
    statusChange() {
      this.reloadPage();
    },
    changeContentType() {
      this.reloadPage();
    },
    handleSortBy(criteria) {
      this.sortOrder = criteria
      this.actionSearchEbooks();
    },
    permFilterVendor() {
      switch (this.auth.role_id) {
        case ROLES.SUPER_ADMIN:
          return true;
        case ROLES.INTERNAL_ADMIN:
          return true;
        case ROLES.INTERNAL_DATA_ENTRY:
          return true;
        default:
          return false;
      }
    },
    setQuery(q = {}) {
      this.$router.push({ path: this.$route.path, state: q }).catch(() => { });
    }
  },
};
</script>

<style scoped>
.books {
  font-family: 'Avenir Next', 'effra', Helvetica, Arial, sans-serif;
}
</style>
